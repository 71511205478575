import { t } from "i18next";
import { AppInputSwitcher } from "../../components/Layouts/AppInputSwitcher/AppInputSwitcher";
import React, { useEffect, useState } from "react";
import BookingGetters from "../../store/onlineBooking/getters";
import { PaymentTypesDetails } from "../../components/Layouts/BookingForm/PaymentTypesDetails";
import { useTranslation } from "react-i18next";
import { TextResource } from "../../@types/DTO/AppSetting";
import i18n from "../../config/i18n";

function NewPersonalDetails({
  reservationSourcePayNow,
  reservationSourcePayLater,
  extrasSelected,
  updateVehiclePrice,
  updateSelectedPrice,
  updateUserPrimaryDetails,
  firstName,
  lastName,
  email,
  phoneNo,
  emailError,
  emailRef,
  phoneRef,
  phoneError,
}) {
  const { t: translate } = useTranslation();
  const appSettings = BookingGetters.getAppSettings();
  const showOnePayMethodTwoPrice =
    appSettings && appSettings["showOnePayMethodTwoPrice"];

  const userId = localStorage.getItem("userID");
  const istabletScreen = window.innerWidth > 768 && window.innerWidth < 1510;

  const handleChange = (key, value) => {
    updateUserPrimaryDetails(key, value);
  };
  const [personalDetailsLabel, setPersonalDetailsLabel] = useState<String>("");
  const [personalDetailsHeader, setPersonalDetailsHeader] =
    useState<string>("");
  //const textResourceInfo = BookingGetters.getTextResource()?.data ?? [];
  const lang = i18n.language;
  const textResource = BookingGetters.getTextResourceDetailsBasedOnLang();
  const data = textResource?.[lang]?.flatMap((item) => item.data || []) || [];
  const isSmallScreen = window.innerWidth <= 768;
  useEffect(() => {
    const dataMap = Object.fromEntries(
      (data ?? []).map((item) => [item.key, item.body])
    );
    setPersonalDetailsHeader(dataMap["personalDetailsHeader"] || "");
    setPersonalDetailsLabel(
      dataMap["personalDetailsLabel"] || translate("Personal Details")
    );
  }, [textResource, lang, translate]);
  return (
    <>
      <div
        className={`bg-white box-border ${
          isSmallScreen ? "p-2" : "p-8"
        } justify-center`}
        style={{ paddingBottom: isSmallScreen ? "2rem" : "" }}
      >
        <h2
          className={"text-black text-base mt-2 font-medium "}
          dangerouslySetInnerHTML={{ __html: String(personalDetailsHeader) }}
        />
        <h2
          className="text-xl text-black pb-8 font-bold mt-[5px]"
          style={{
            paddingLeft: isSmallScreen ? ".8rem" : "",
            paddingTop: isSmallScreen ? "2rem" : "",
          }}
        >
          {personalDetailsLabel}
        </h2>
        <div
          className={`grid sm:grid-cols-2 ${
            istabletScreen ? "md:grid-cols-2" : "md:grid-cols-3"
          } gap-4`}
        >
          <AppInputSwitcher
            type="text"
            className={`h-20 sm:w-full box-border ${
              isSmallScreen ? "px-2" : "px-8"
            }`}
            required={true}
            label={t("First Name")}
            key="firstName"
            value={firstName || ""}
            onchange={(e) => handleChange("firstName", e.toString())}
          />
          <AppInputSwitcher
            type="text"
            required={true}
            className={`h-20 sm:w-full box-border ${
              isSmallScreen ? "px-2" : "px-8"
            }`}
            label={t("Last Name")}
            key="lastName"
            value={lastName || ""}
            onchange={(e) => handleChange("lastName", e.toString())}
          />
          <div className="w-full">
            <AppInputSwitcher
              type="email"
              required={true}
              className={`h-20 sm:w-full box-border ${
                isSmallScreen ? "px-2" : "px-8"
              }`}
              label={t("Email")}
              key="email"
              value={email || ""}
              onchange={(e) => handleChange("email", e.toString())}
              inputRef={emailRef}
            />
            {emailError && (
              <p
                style={{ color: "red", fontStyle: "italic" }}
                className="text-sm"
              >
                {emailError}
              </p>
            )}
          </div>
          <div>
            <AppInputSwitcher
              type="mobile"
              required={appSettings?.markPhoneNoRequiredForPrivate}
              className={`h-20 sm:w-full box-border ${
                isSmallScreen ? "px-2" : "px-8"
              }`}
              label={t("Mobile Number")}
              key="telephone"
              value={phoneNo || ""}
              onchange={(e) => handleChange("telephone", e.toString())}
              inputRef={phoneRef}
            />
            {phoneError && (
              <p
                style={{ color: "red", fontStyle: "italic" }}
                className="text-sm"
              >
                {phoneError}
              </p>
            )}
          </div>
        </div>
      </div>
      {!showOnePayMethodTwoPrice ||
      (appSettings?.showAllPayOptionsInResArea &&
        userId != null &&
        userId != "null" &&
        userId != "") ? (
        <div className="bg-white box-border p-10">
          <PaymentTypesDetails
            reservationSourcePayNow={reservationSourcePayNow}
            reservationSourcePayLater={reservationSourcePayLater}
            extrasPrice={extrasSelected?.extrasPrice}
            updateVehiclePrice={updateVehiclePrice}
            updateSelectedPrice={updateSelectedPrice}
          />
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default NewPersonalDetails;
