import AppStandardButton from "../../components/UI/AppButtons";
import { BookingInformationRow } from "../../components/Layouts/BookingInformation/BookingInformation";
import React, { useEffect, useState } from "react";
import i18n, { t } from "i18next";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BookingApi } from "../../API/Apis/BookingApi";
import { StripeTransactionDetails } from "../../@types/Booking/stripeTransactionDetails";
import BookingGetters from "../../store/onlineBooking/getters";
import TagManager from "react-gtm-module";
import { TextResource } from "../../@types/DTO/AppSetting";
import { formatTheme } from "../../config/MuiColorThemes";
import { createTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
export function BookingConfirmPageStripe() {
  // const [searchParams, setSearchParams] = useSearchParams();
  const appSettings = BookingGetters.getAppSettings();
  const redirectWebCheckIn = appSettings && appSettings?.redirectWebCheckIn;
  const enableTagManager = appSettings && appSettings["enableTagManager"];
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const price = searchParams.get("amount") || "";
  const dbId = searchParams.get("prefixNo") || "";
  const [priceData, setPriceData] = useState<string>("");
  const [prefixNo, setPrefixNo] = useState<string>("");
  const stripePageRedirectionUrl: StripeTransactionDetails = {
    sessionId: searchParams.get("sessionId") || "",
    priceId: searchParams.get("priceId") || "",
    reservationId: searchParams.get("reservationId") || "",
    userId: searchParams.get("userId") || "",
    amount: searchParams.get("amount") || "",
    prefixNo: searchParams.get("prefixNo") || "",
  };

  useEffect(() => {
    BookingApi.savePaymentForStripe(stripePageRedirectionUrl);
    navigate(location.pathname, { replace: true });
    setPriceData(price);
    setPrefixNo(dbId);
  }, [navigate, location.pathname]);
  const lang = i18n.language;
  const { t: translate } = useTranslation();
  const textResource = BookingGetters.getTextResourceDetailsBasedOnLang();
  const data = textResource?.[lang]?.flatMap((item) => item.data || []) || [];
  const [confirmationPageLabel, setConfirmationPageLabel] =
    useState<String>("");
  useEffect(() => {
    const confirmationPageTextData = data.find(
      (item) => item.key === "ConfirmationPageTextLabel"
    );
    setConfirmationPageLabel(
      confirmationPageTextData?.body ||
        t("Congratulations! Your Booking Is Completed")
    );
  }, [lang, textResource, translate]);
  const dynamicPalette = formatTheme();
  const dynamicTheme = createTheme(dynamicPalette);
  const primaryColor = dynamicTheme?.palette["primary"]?.main;
  const secondaryColor = dynamicTheme?.palette["secondaryColor"]?.main;
  const confirmationTextColor =
    dynamicTheme?.palette["confirmationTextColor"]?.contrastText;
  const [showGtagManagerEvent, setGtagManagerEvent] = useState(
    appSettings?.gtagManagerEvent || ""
  );
  const [showGTagManagerSendTo, setGTagManagerSendTo] = useState(
    appSettings?.gTagManagerSendTo || ""
  );
  const [showGTagManagerCurrency, setGTagManagerCurrency] = useState(
    appSettings?.gTagManagerCurrency || ""
  );
  const [isTagManagerDataLayerEnabled, setIsTagManagerDataLayerEnabled] =
    useState(false);
  const [showGtagManagerValue, setGtagManagerValue] = useState(false);
  const [showGTagManagerTransactionId, setGTagManagerTransactionId] =
    useState(false);
  const [tagManagerId, setTagManagerId] = useState("");
  const [isTagManagerEnabled, setIsTagManagerEnabled] = useState(false);
  const showPrice = showGtagManagerValue ? priceData : null;
  const showDbId = showGTagManagerTransactionId ? prefixNo : null;
  const HideOneButton =
    appSettings && appSettings?.hideButtonAtConfirmationPage;

  useEffect(() => {
    setIsTagManagerDataLayerEnabled(!!appSettings?.enableDataLayer);
    setGTagManagerCurrency(appSettings?.gTagManagerCurrency || "");
    setGTagManagerSendTo(appSettings?.gTagManagerSendTo || "");
    setGtagManagerEvent(appSettings?.gtagManagerEvent || "");
    setGtagManagerValue(!!appSettings?.enableGtagManagerValue);
    setGTagManagerTransactionId(!!appSettings?.enableGTagManagerTranscationId);
    setIsTagManagerEnabled(!!appSettings?.enableTagManager);
    setTagManagerId(appSettings?.tagManagerId || "");
  }, [appSettings]);

  useEffect(() => {
    if (isTagManagerDataLayerEnabled) {
      const tagManagerArgs = {
        dataLayer: {
          event: showGtagManagerEvent,
          send_to: showGTagManagerSendTo,
          value: showPrice,
          currency: showGTagManagerCurrency,
          transaction_id: showDbId,
        },
      };
      TagManager.dataLayer(tagManagerArgs);
    }
  }, [isTagManagerDataLayerEnabled]);
  useEffect(() => {
    if (isTagManagerEnabled && tagManagerId != "") {
      const tagManagerArgs = {
        gtmId: tagManagerId,
      };
      TagManager.initialize(tagManagerArgs); // google tag manager integrated along with the preferences
    }
  }, [tagManagerId, isTagManagerEnabled]);

  const handleCallback = () => {
    fetch("")
      .then((response) => {})
      .catch((error) => {});
  };
  const secondPageBackgroundColor =
    appSettings && appSettings["secondPageBackgroundColor"];

  return (
    <section
      className="min-h-[100vh] box-border px-10 md:px-44  flex flex-col border-t-2 border-solid border-grey-300"
      style={{
        background: secondPageBackgroundColor ? secondPageBackgroundColor : "",
      }}
    >
      <header className="grow flex flex-col items-center justif-center gap-4 box-border py-8">
        <img src="/assets/images/success-icon@2x.png" className="h-36 w-36" />
        <h1
          className="text-3xl font-bold py-2"
          style={{
            color: confirmationTextColor
              ? confirmationTextColor
              : secondaryColor,
          }}
        >
          {confirmationPageLabel}
        </h1>
        <p className="font-bold text-black-600">
          {/* Reservation Code:{" "} */}
          {/* Web Check In Code:{" "}
            <span className="font-bold text-[#5cbc14]">DG-87450</span> */}
        </p>
        <div className="flex flex-col-2 ">
          {" "}
          <AppStandardButton
            href="/embed"
            className={"w-fit py-4 mx-0 md:mx-8"}
            variant="outlined"
            /* color="secondaryColor"*/
            style={{
              borderRadius: "5",
              color: confirmationTextColor
                ? confirmationTextColor
                : primaryColor,
            }}
          >
            {/* Go to Account */}
            <span
              className="normal-case p-1 text-lg"
              style={{
                color: confirmationTextColor
                  ? confirmationTextColor
                  : secondaryColor,
              }}
            >
              {" "}
              Homepage
            </span>
          </AppStandardButton>
          {!redirectWebCheckIn
            ? !HideOneButton && (
                <AppStandardButton
                  href="/embed"
                  className={"w-fit py-4 mx-2 md:mx-8 whitespace-nowrap"}
                  /* color="secondaryColor"*/
                  variant="outlined"
                  style={{
                    borderRadius: "5",
                    borderColor: confirmationTextColor
                      ? confirmationTextColor
                      : primaryColor,
                  }}
                >
                  {/*Go to Account*/}
                  <span
                    className="normal-case  p-1 text-lg whitespace-nowrap"
                    style={{
                      color: confirmationTextColor
                        ? confirmationTextColor
                        : secondaryColor,
                    }}
                  >
                    {" "}
                    Dashboard
                  </span>
                </AppStandardButton>
              )
            : !HideOneButton && (
                <AppStandardButton
                  href={`/booking/web-check-in?lang=${i18n.language}`}
                  className={"w-fit py-4 mx-2 md:mx-8 whitespace-nowrap"}
                  /*color="primary"*/
                  variant="outlined"
                  style={{
                    borderRadius: "5",
                    borderColor: confirmationTextColor
                      ? confirmationTextColor
                      : primaryColor,
                  }}
                >
                  <span
                    className="normal-case p-1 text-lg whitespace-nowrap"
                    style={{ color: secondaryColor }}
                  >
                    {" "}
                    Web Check-In
                  </span>
                </AppStandardButton>
              )}
        </div>
      </header>
      <main></main>
      <footer className="text-gray-600 box-border pb-32 ">
        {/* Web Checkin Info */}
        {/* Web Checkin Information */}

        <div className="box-border border-2 border-solid border-grey-200 px-8"></div>
      </footer>
    </section>
  );
}
